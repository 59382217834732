import React from "react";
import TinderCard from "react-tinder-card";
import Card from "../Card";

export default function Questions() {
    return (
        <Card>
            <div className="text-xl lg:text-3xl font-bold tracking-tight max-w-[500px] lg:min-w-[300px] mb-[20px]">Wir haben noch ein paar spannende Fragen</div>
            <TinderCard className="h-[400px] border border-light/80 hover:border-accent-3/60 rounded-md p-[20px] bg-dark text-center transition-colors">
                <div className="text-lg lg:text-2xl font-semibold mb-[20px]">Bist Du ein...</div>
                <div className="grid grid-cols-2 h-[calc(100%-50px)]">
                    <div className="grid place-items-center bg-dark-3 rounded-l-md text-sm lg:text-lg">Morgenmuffel?</div>
                    <div className="grid place-items-center bg-dark-4 rounded-r-md text-sm lg:text-lg">Frühaufsteher?</div>
                </div>
            </TinderCard>
            <TinderCard className="h-[400px] border border-light/80 hover:border-accent-3/60 rounded-md p-[20px] bg-dark text-center transition-colors">
                <div className="text-lg lg:text-2xl font-semibold mb-[20px]">Bist Du ein...</div>
                <div className="grid grid-cols-2 h-[calc(100%-50px)]">
                    <div className="grid place-items-center bg-dark-3 rounded-l-md text-sm lg:text-lg">Kaffeetrinker?</div>
                    <div className="grid place-items-center bg-dark-4 rounded-r-md text-sm lg:text-lg">Teetrinker?</div>
                </div>
            </TinderCard>
        </Card>
    )
}