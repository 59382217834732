import React, { useState } from "react";
import { Link } from "react-router-dom";

export default function Header() {
    const [isNavOpen, setIsNavOpen] = useState(false);

    return (
        <>
            <div className="absolute left-[40px] top-[30px]">
                <Link to="/" className="text-xl lg:text-4xl font-bold tracking-tight hover:cursor-pointer text-light/80 hover:text-light">PERFEKTE WG</Link>
            </div>
            <div onClick={() => setIsNavOpen(true)} className="lg:hidden absolute right-[40px] top-[35px] space-y-[8px]">
                <span className="block h-[2px] w-[32px] bg-light/80"></span>
                <span className="block h-[2px] w-[32px] bg-light/80"></span>
                <span className="block h-[2px] w-[32px] bg-light/80"></span>
            </div>
            {isNavOpen === true && (
                <div className="lg:hidden h-screen w-screen absolute top-0 left-0 z-[1] bg-dark/80 backdrop-blur-sm">
                    <div className="absolute left-[40px] top-[30px]">
                        <Link to="/" className="text-xl lg:text-4xl font-bold tracking-tight hover:cursor-pointer text-light/80 hover:text-light">PERFEKTE WG</Link>
                    </div>
                    <div onClick={() => setIsNavOpen(false)} className="absolute right-[40px] top-[30px]">
                        <svg
                            className="h-[32px] w-[32px] text-light"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="#eeeeee"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <line x1="18" y1="6" x2="6" y2="18" />
                            <line x1="6" y1="6" x2="18" y2="18" />
                        </svg>
                    </div>
                    <div className="flex flex-col items-center gap-[15px] mt-[80px]">
                        <Link to="produkte" className="bg-dark/80 text-center py-[10px] px-[15px] w-full text-xl font-semibold leading-6 hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">Produkte</Link>
                        <Link to="support" className="bg-dark/80 text-center py-[10px] px-[15px] w-full text-xl font-semibold leading-6 hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">Support</Link>
                    </div>
                </div>
            )}
            <div className="hidden lg:flex w-full justify-center gap-[100px] p-[40px]">
                <Link to="produkte" className="text-xl font-semibold leading-6 hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">Produkte</Link>
                <Link to="support" className="text-xl font-semibold leading-6 hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">Support</Link>
            </div>
        </>
    )
}