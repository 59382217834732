import React from "react";
import { Route, createBrowserRouter, createRoutesFromElements } from "react-router-dom";
import ParentOutlet from "./components/ParentOutlet";
import SimpleOutlet from "./components/SimpleOutlet";
import Flatmate from "./components/site/Flatmate";
import Home from "./components/site/Home";
import Questions from "./components/site/Questions";
import Select from "./components/site/Select";
import SharedApartment from "./components/site/SharedApartment";

// https://reactrouter.com/en/main/route/route
// https://stackoverflow.com/a/40591955
export const router =
    createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route path="/" element={<Home />} />
                <Route path="erstellen" element={<ParentOutlet />}>
                    <Route index element={<Select />} />
                    <Route path="mitbewohner" element={<SimpleOutlet />}>
                        <Route index element={<Flatmate />} />
                        <Route path="fragen" element={<Questions />} />
                    </Route>
                    <Route path="wohngemeinschaft" element={<SharedApartment />} />
                </Route>
                <Route path="produkte" element={<div>Produkte</div>} />
                <Route path="support" element={<div>Support</div>} />
            </>
        ),
        { basename: "/" },
    );
