import React, { ReactNode } from "react";

interface CardProps {
    children?: ReactNode
}

export default function Card({ children }: CardProps) {
    return (
        <div className="lg:mb-[50px] p-[50px] lg:bg-dark/80 lg:backdrop-blur-sm rounded-md">
            {children}
        </div>
    )
}
