import { zodResolver } from "@hookform/resolvers/zod";
import React from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Card from "../Card";
import { FlatmateFormData, flatmateSchema } from "../scheme/flatmate";

export default function Flatmate() {
    const navigate = useNavigate();

    const { register, handleSubmit, formState: { errors, isValid } } = useForm<FlatmateFormData>({
        mode: "all",
        resolver: zodResolver(flatmateSchema)
    })

    function submitForm(data: FlatmateFormData) {
        if (isValid) {
            navigate("fragen");
        }
    }

    return (
        <Card>
            <div className="mx-auto text-xl font-semibold tracking-tight max-w-[500px] lg:min-w-[300px]">
                <div className="text-xl lg:text-3xl font-bold tracking-tight max-w-[500px] lg:min-w-[300px]">Bevor Du loslegen kannst, benötigen wir noch ein paar Infos über Dich.</div>
                <form onSubmit={handleSubmit(submitForm)} className="mt-[20px] flex flex-col gap-[15px]">
                    <input {...register("name")} required={true} placeholder={"Name"} type="text" className="required:border-light/50 w-full border rounded-md p-[10px] font-normal text-sm text-light bg-dark/80 focus:outline-none focus:border-light" />
                    <p className="text-sm text-red-400 mt-[-10px]">{errors.name?.message}</p>
                    <input {...register("dob")} required={true} type="date" className="required:border-light/50 w-full border rounded-md p-[10px] font-normal text-sm text-light bg-dark/80 focus:outline-none focus:border-light" />
                    <p className="text-sm text-red-400 mt-[-10px]">{errors.dob?.message}</p>
                    <input {...register("email")} required={true} placeholder={"E-Mail"} type="text" className="required:border-light/50 w-full border rounded-md p-[10px] font-normal text-sm text-light bg-dark/80 focus:outline-none focus:border-light" />
                    <p className="text-sm text-red-400 mt-[-10px]">{errors.email?.message}</p>
                    <input {...register("password")} required={true} placeholder={"Passwort"} type="password" className="required:border-light/50 w-full border rounded-md p-[10px] font-normal text-sm text-light bg-dark/80 focus:outline-none focus:border-light" />
                    <p className="text-sm text-red-400 mt-[-10px]">{errors.password?.message}</p>
                    <div className="w-full border-b border-light/50 mt-[10px] mb-[-10px]"></div>
                    <button type="submit" className="mt-[20px] mx-auto bg-accent-3 disabled:bg-light rounded-md px-[70px] w-full py-[15px] font-semibold text-lg text-dark-3 disabled:hover:text-dark-3 hover:text-white flex justify-center items-center">
                        Weiter
                    </button>
                </form>
            </div>
        </Card >
    )
}
