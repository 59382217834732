import React from "react";
import background2 from "../assets/background_people.jpg"; // Bild von <a href="https://de.freepik.com/fotos-kostenlos/freunde-haben-spass-beim-pokern_22633137.htm#query=wohngemeinschaft&position=3&from_view=keyword&track=sph">Freepik</a>

export default function Background() {

    return (
        <>
            <div className="absolute top-0 left-0 h-screen w-screen z-[-2]">
                <img src={background2} id="background" alt="background" className="object-cover h-screen w-screen" />
            </div>
            <div className="absolute top-0 left-0 h-screen w-screen bg-dark/60 z-[-1] backdrop-blur-sm"></div>
        </>
    )
}
