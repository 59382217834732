import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../Card";

export default function Select() {
    const navigate = useNavigate();

    function mitbewohner() {
        navigate("mitbewohner");
    }

    function wohngemeinschaft() {
        navigate("wohngemeinschaft");
    }

    return (
        <Card>
            <div className="lg:mx-auto text-center text-4xl font-bold tracking-tight max-w-[500px]">
                Wer oder was soll Dein <span className="color-text-animated">perfektes Match</span> sein?
                <div className="mt-[50px] grid gap-[20px]">
                    <button onClick={mitbewohner} className="bg-accent-3 rounded-md p-[20px] font-semibold text-lg text-dark-3 hover:text-white flex justify-center items-center">
                        Mitbewohner:in
                    </button>
                    <div className="flex row">
                        <div className="w-full border-b border-light/50 mb-[9px]"></div>
                        <div className="w-[100px] text-light/80 font-semibold text-sm">oder</div>
                        <div className="w-full border-b border-light/50 mb-[9px]"></div>
                    </div>
                    <button onClick={wohngemeinschaft} className="bg-accent-3 rounded-md p-[20px] font-semibold text-lg text-dark-3 hover:text-white flex justify-center items-center">
                        Wohngemeinschaft
                    </button>
                </div>
            </div>
        </Card>
    )
}
