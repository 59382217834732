import React from "react";
import { Outlet } from "react-router-dom";
import Background from "./Background";
import CenterContainer from "./CenterContainer";
import Footer from "./Footer";
import Header from "./Header";

export default function ParentOutlet() {
    return (
        <div className="text-light">
            <Header />
            <Background />
            <CenterContainer>
                <Outlet />
            </CenterContainer >
            <Footer />
        </div >
    )
}
