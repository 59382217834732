import { z } from "zod"

const passwordRegex = /^((?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9]).{6,})\S$/
const emailRegex = /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/

export const flatmateSchema = z.object({
    name: z.string()
        .min(1, "Dieses Feld muss mindestens 1 Zeichen enthalten")
        .max(255, "Dieses Feld darf maximal 255 Zeichen enthalten"),
    dob: z.string().min(8, "Dieses Feld muss mindestens 8 Zeichen enthalten"),
    email: z.string()
        .regex(emailRegex, "Die eingegebene Email ist ungültig")
        .min(1, "Dieses Feld muss mindestens 1 Zeichen enthalten")
        .max(255, "Dieses Feld darf maximal 255 Zeichen enthalten"),
    password: z.string()
        .regex(passwordRegex, "Bitte geben Sie ein gültiges Passwort ein. Dieses muss mindestens 6 Ziffern, dabei 1 Großbuchstaben, 1 Kleinbuchstaben und 1 Zahl enthalten")
        .min(6, "Dieses Feld muss mindestens 6 Zeichen enthalten")
})

export type FlatmateFormData = z.infer<typeof flatmateSchema>
