import React from "react";

export default function Footer() {
    return (
        <div className="absolute left-0 w-full flex flex-wrap gap-x-[20px] gap-y-[10px] px-[20px] py-[10px] justify-center bg-dark backdrop-blur-sm top-[100vh] lg:gap-[100px] lg:p-[20px]">
            <a href="/" className="lg:text-sm leading-6 tracking-tight hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">Impressum</a>
            <a href="/" className="lg:text-sm leading-6 tracking-tight hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">Datenschutz</a>
            <a href="/" className="lg:text-sm leading-6 tracking-tight hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">AGB</a>
            <a href="/" className="lg:text-sm leading-6 tracking-tight hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">Kontakt</a>
            <a href="/" className="lg:text-sm leading-6 tracking-tight hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">Über uns</a>
            <a href="/" className="lg:text-sm leading-6 tracking-tight hover:cursor-pointer hover:underline hover:text-accent-3 hover:underline-offset-8">FAQ</a>
        </div>
    )
}