import React from "react";
import { useNavigate } from "react-router-dom";
import Card from "../Card";

export default function SharedApartment() {
    const navigate = useNavigate();

    return (
        <Card>
            <div className="lg:mx-auto text-center text-4xl font-bold tracking-tight max-w-[500px]">
                Wie heißt Du?
                <button onClick={() => null} className="bg-accent-3 rounded-md p-[20px] font-semibold text-lg text-dark-3 hover:text-white flex justify-center items-center">
                    Weiter
                </button>
            </div>
        </Card >
    )
}
