import React from "react";
import { useNavigate } from "react-router-dom";
import Background from "../Background";
import Card from "../Card";
import CenterContainer from "../CenterContainer";
import Footer from "../Footer";
import Header from "../Header";

export default function Home() {
    const navigate = useNavigate();

    function start() {
        navigate("erstellen");
    }

    return (
        <div className="text-light">
            <Background />
            <Header />
            <CenterContainer>
                <Card>
                    <div className="mx-auto text-4xl font-bold tracking-tight max-w-[500px]">Schluss mit Mitbewohnern, die nicht zu Dir passen.<br />
                        Finde Dein <span className="color-text-animated">perfektes Match!</span></div>
                    <div className="mx-auto w-fit mt-[50px]">
                        <button className="rounded-full font-semibold text-lg px-[70px] lg:px-[100px] py-[20px] bg-accent-3 text-dark-3 hover:text-white" onClick={start}>
                            Jetzt starten
                        </button>
                    </div>
                </Card>
            </CenterContainer>
            <Footer />
        </div>
    )
}
