import React, { ReactNode } from "react";

interface CenterContainerProps {
    children?: ReactNode
}

export default function CenterContainer({ children }: CenterContainerProps) {

    return (
        <div className="flex h-screen md:h-[calc(100vh-50px)]">
            <div className="flex-col self-center lg:w-fit w-full mx-auto">
                {children}
            </div>
        </div>
    )
}
